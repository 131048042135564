import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Page = () => {
    return (
        <Fragment>
            <CommonHero heading="Licensed Aesthetician, Dawn Marie">
                <StaticImage
                    src="../../../assets/images/about-us/dawn/hero.jpg"
                    alt="Dawn Marie"
                    width={435}
                    height={500}
                    loading="eager"
                />
            </CommonHero>

            <Article>
                <p>
                    Dawn’s immense training in a wide range of healing modalities over the course of
                    nearly two decades, coupled with her passion to serve and empower her clients,
                    has been her driving force in creating a unique healing experience to her
                    community ~ both in and away from the treatment room. <br /> <br />
                    As a licensed aesthetician, Dawn is well known for her healing touch and keen
                    eye for detail. As both certified yoga teacher and embodiment coach, her
                    offerings of guiding her clients towards a mind-body connection via somatic has
                    proven to add to her care when being pampered in the treatment room.
                    <br /> <br />
                    In addition to her love and embodiment of the healing arts, Dawn is a proud
                    mother of two sons, world traveler, writer and perpetual student of life.
                </p>

                <h3
                    style={{
                        fontFamily: "'Manrope', sans-serif",
                        color: '#E46D76',
                        fontWeight: 800,
                    }}
                >
                    CRYO Skin Therapy
                </h3>
                <p>Dawn Marie provides CRYO Skin Therapy services at Dana Point location only.</p>
                <p>
                    CRYO Skin Therapy is a relaxing, natural and non-invasive way to aid in the
                    tightening of the skin. This treatment targets fine lines and wrinkles,
                    diminishes hyperpigmentation, stimulates cell turnover rate and the promotion of
                    collagen growth.
                </p>
                <p>
                    Rejuvenated and glowing skin are the results of improved circulation, diminished
                    pore size, and enhanced skin elasticity.
                </p>
                <h5>VI PEEL Face</h5>
                <p>
                    VI Peel Precision Plus for face is clinically proven to lift existing
                    hyperpigmentation while suppressing future pigment product. Safe for all skin
                    types.
                </p>
                <h5>VI PEEL Body</h5>
                <p>
                    VI Peel Body is the first and ONLY professional-grade body peel for all skin
                    types and tones that’s specifically designed to reduce pigmentation, clear body
                    acne, fade surgical scars, and smooth away stretch marks.
                </p>
                <h5>Divine Facial</h5>
                <p>
                    Aromatherapy infused cleansing treatment followed by exfoliation, soothing
                    masque and facial massage. Divine!
                </p>
                <h5>Dermaplane</h5>
                <p>
                    Exfoliation treatment which removes both dead skin cells and fine vellus
                    hair(peach fuzz). Results: brighter, ultra smooth complexion.
                </p>
            </Article>
        </Fragment>
    );
};

export default Page;
